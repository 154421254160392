import React, { useState } from "react"
import { animated } from "react-spring"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useStore } from "../../../@core/scenes/mainStore"
import LANGUAGES from "../../../Const/languages"

export const SelectSecteursPanel = ({ clickOutsideHandler, style }) => {
  const [showEntreprises, setShowEntreprises] = useState(false)
  const [EntreprisesObj, setEntreprisesObj] = useState(null)
  const [secteur, setSecteur] = useState(null)
  const LANG = useStore(state => state.language)



  // Utiliser static Query pour les Secteur d'activités
  let data = useStaticQuery(graphql`
    query {
      allWpSecteur(sort: { order: ASC, fields: name }) {
        nodes {
          language {
            code
          }
          name
          entreprises {
            nodes {
              title
              slug
            }
          }
        }
      }
    }
  `)

  // SELECT ONLY THE CURRENT LANGUAGE
  const dataLangFiltered = data.allWpSecteur.nodes.filter(
    value => value.language.code === LANG
  )

  const onClickHandler = value => {
    const entreprises = value.entreprises.nodes
    // Alphabetical order of entreprises Title
    entreprises.sort((a, b) => {
      return a.title < b.title ? -1 : 1
    })
    setShowEntreprises(true)
    setSecteur(value.name)
    setEntreprisesObj(entreprises)
  }

  return (
    <animated.div style={style} className="select-cat-panel-container">
      <div className="select-cat-panel-bg" onClick={clickOutsideHandler}></div>
      <div className="cat-window-container">
        <div className="cat-window">
          <h3>{LANGUAGES[LANG].filterSector}</h3>
          <ul className="category-list">
            {dataLangFiltered.map((value, index) => {
              return (
                <li key={index} onClick={() => onClickHandler(value)}>
                  {value.name}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {showEntreprises && (
        <div className="cat-window-container cat-window-second">
          <div className="cat-window cat-window-entreprises">
            <h3>{LANGUAGES[LANG].selection} {secteur}</h3>
            <ul className="category-list">
              {EntreprisesObj.map(node => (
                <li key={node.slug}>
                  <Link to={`/${node.slug}`}>{node.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </animated.div>
  )
}
