import React from "react"
import { animated } from "react-spring"
import { Link } from "gatsby"
import LANGUAGES from "../../../Const/languages"
import { useStore } from "../../../@core/scenes/mainStore"

export const SelectEntreprisesPanel = ({
  clickOutsideHandler,
  style,
  data,
}) => {

  const LANG = useStore(state => state.language)

  return (
    <animated.div style={style} className="select-cat-panel-container">
      <div className="select-cat-panel-bg" onClick={clickOutsideHandler}></div>
      <div className="cat-window-container">
        <div className="cat-window cat-window-entreprises">
          <h3>{LANGUAGES[LANG].allEntreprises}</h3>
          <ul className="category-list">
            {data.map(node => (
              <li key={node.slug}>
                <Link to={`/${node.slug}`}>{node.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </animated.div>
  )
}
