import React from "react"
import { useStore } from "../../../@core/scenes/mainStore"
import LANGUAGES from "../../../Const/languages"


export const TriggerSelectCategory = ({ clickHandler, clickHandler2, style }) => {
  const LANG = useStore(state => state.language)


  return (
    <>
      <div className="trigger-select-cat" onClick={clickHandler2} style={style}>
        <h3>{LANGUAGES[LANG].brands}</h3>
        <div className="select-button">
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.1 2.6C9.1 4.03594 7.93594 5.2 6.5 5.2C5.06406 5.2 3.9 4.03594 3.9 2.6C3.9 1.16406 5.06406 0 6.5 0C7.93594 0 9.1 1.16406 9.1 2.6Z" fill="black"/>
            <path d="M5.2 9.1C5.2 10.5359 4.03594 11.7 2.6 11.7C1.16406 11.7 0 10.5359 0 9.1C0 7.66406 1.16406 6.5 2.6 6.5C4.03594 6.5 5.2 7.66406 5.2 9.1Z" fill="black"/>
            <path d="M13 9.1C13 10.5359 11.8359 11.7 10.4 11.7C8.96406 11.7 7.8 10.5359 7.8 9.1C7.8 7.66406 8.96406 6.5 10.4 6.5C11.8359 6.5 13 7.66406 13 9.1Z" fill="black"/>
          </svg>
        </div>
      </div>
      <div className="trigger-select-cat" onClick={clickHandler} style={style}>
        <h3>{LANGUAGES[LANG].sectors}</h3>
        <div className="select-button">
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="2" rx="1" fill="#292425"/>
            <rect y="4" width="12" height="2" rx="1" fill="#292425"/>
            <rect y="8" width="12" height="2" rx="1" fill="#292425"/>
          </svg>
        </div>
      </div>
    </>
  )
}
