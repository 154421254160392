import * as THREE from "three"
import vertexShader from "../shaders/galerie/vertex_vague.glsl"
import fragmentShader from "../shaders/galerie/fragment.glsl"
import gsap from "gsap"
import { useStore } from "./store"


export default class CarrouselItem {
  constructor(texture, geometry, order, pos, slug) {
    this.texture = texture
    this.geometry = geometry
    this.order = order
    this.slug = {
      fr: slug.fr,
      en: slug.en
    }

    const uniforms = {
      u_time: { type: "t", value: 0 },
      u_texture: { type: "t", value: null },
      u_Frequency: { type: "f", value: 1 },
    }
    this.material = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      side: THREE.DoubleSide,
    })
    this.mesh = new THREE.Mesh(geometry, this.material)
    this.mesh.material.uniforms.u_texture.value = this.texture
    this.mesh.position.set(pos.x, 0, pos.y)

    this.moving = false
    this.originPos = null
    this.exploding = false
  }
  update(elapsedTime) {
    this.material.uniforms.u_time.value = elapsedTime
  }
  OnMouseEnter() {
    // change mouse pointer
    document.body.style.cursor = "pointer"
    useStore.setState({ onHoverThumb: true })
    // saved Origin Pos
    if (!this.moving && !this.exploding) {
      if (this.originPos === null) {

        this.originPos = new THREE.Vector3(
          this.mesh.position.x,
          this.mesh.position.y,
          this.mesh.position.z
        )
      }
    }
    gsap.to(this.mesh.scale, {
      x: 1.1,
      y: 1.1,
      duration: 1,
      ease: "power4.out",
      onComplete: () => (this.onHover = false),
    })

  }
  OnMouseLeave() {
    document.body.style.cursor = "initial"
    useStore.setState({ onHoverThumb: false })

    if (!this.moving && this.originPos !== null && !this.exploding) {
      this.leave()
    }
  }
  explosion() {

    if (!this.exploding) {
      this.exploding = true
      // call explosion from store
      const actions = useStore.getState().actions
      actions.zoomAndExplose(this)
    }
  }
  hide() {
    this.posYBackup = this.mesh.position.y
    this.mesh.position.y = -100
  }
  show() {
    this.mesh.scale.set(1, 1, 1)
    this.mesh.position.y = 0
  }
  reset() {
    this.show()
    this.exploding = false
  }
  leave() {
    gsap.to(this.mesh.scale, {
      x: 1,
      y: 1,
      duration: 1,
    })
    // Position
    gsap.to(this.mesh.position, {
      x: this.originPos.x,
      z: this.originPos.z,
    })
  }
  up() {
    gsap.to(this.mesh.position, {
      y: this.mesh.position.y + 5,
      duration: 1,
    })
  }
  down() {}
}
