import React, { useEffect, useState } from "react"
import { useTransition } from "react-spring"
import { useStore } from "../../../@core/galerie/store"
import { SelectSecteursPanel } from "./selectSecteursPanel"
import { SelectEntreprisesPanel } from "./selectEntreprisesPanel"
import { TriggerSelectCategory } from "./triggerSelectCategory"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as mainStore from "../../../@core/scenes/mainStore"

export const SortSelectNavigation = () => {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const actions = useStore(state => state.actions)
  const LANG = mainStore.useStore(state => state.language)


  const clickHandlerSecteur = () => {
    // animation de la camera
    if (!open) actions.sortCategoryZoomOut()
    else actions.sortCategoryZoomIn()
    setOpen(!open)
  }
  const clickHandlerEntreprises = () => {
    // animation de la camera
    if (!open2) actions.sortCategoryZoomOut()
    else actions.sortCategoryZoomIn()

    setOpen2(!open2)
  }

  useEffect(() => {
    if (open || open2) {
      useStore.setState({ sortPanelOpen: true })
    } else {
      useStore.setState({ sortPanelOpen: false })
    }
  }, [open, open2])

  const transitions = useTransition(open, {
    keys: null,
    from: { opacity: 0, marginTop: -15 },
    enter: { opacity: 1, marginTop: 0 },
    leave: { opacity: 0, marginTop: -15 },
    config: {
      duration: 500,
    },
  })
  const transitions2 = useTransition(open2, {
    keys: null,
    from: { opacity: 0, marginTop: -15 },
    enter: { opacity: 1, marginTop: 0 },
    leave: { opacity: 0, marginTop: -15 },
    config: {
      duration: 500,
    },
  })

  // Queries
  // Utiliser static Query pour les Secteur d'activités

  const dataEntreprises = useStaticQuery(graphql`
    query {
      allWpEntreprise(sort: { fields: title, order: ASC }) {
        nodes {
          title
          slug
          language {
            code
          }
        }
      }
    }
  `)

  // FILTER LANGUAGE
  let dataLangFiltered = dataEntreprises.allWpEntreprise.nodes.filter(
    value => value.language.code === LANG
  )

  return (
    <div className="sort-category-container">
      <TriggerSelectCategory
        open={open}
        clickHandler={clickHandlerSecteur}
        clickHandler2={clickHandlerEntreprises}
      />
      {/* Panel pour Secteurs */}
      {transitions(
        (styles, item) =>
          item && (
            <SelectSecteursPanel
              style={styles}
              clickOutsideHandler={clickHandlerSecteur}
            />
          )
      )}
      {/* Panel des entreprises */}
      {transitions2(
        (styles, item) =>
          item && (
            <SelectEntreprisesPanel
              data={dataLangFiltered}
              style={styles}
              clickOutsideHandler={clickHandlerEntreprises}
            />
          )
      )}
    </div>
  )
}
