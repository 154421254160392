import { useStore } from "../@core/galerie/store"

// Mouse Camera Movement
export const ClickAndMove = (actionLeft, actionRight) => {
  let dragging = false
  let down = false
  const mousePosPrev = { x: 0, y: 0 }

  const onTouchStart = event => {
    down = true
    if (event.clientX) {
      mousePosPrev.x = event.clientX
      mousePosPrev.y = event.clientY
    } else {
      mousePosPrev.x = event.changedTouches[0].clientX
      mousePosPrev.y = event.changedTouches[0].clientY
    }
  }
  const onTouchMove = event => {
    if (down) {
      dragging = true
    }
  }
  const onMouseMove = event => {
    if (down) {
      onTouchMove(event)
    }
  }
  const onTouchEnd = event => {
    let posX = 0
    const diff = 50
    if (dragging) {
      // If Not Mobile
      if (event.clientX) {
        posX = event.clientX
      } else {
        // Touch devices
        posX = event.changedTouches[0].clientX
      }

      if (posX > mousePosPrev.x + diff ) {
        actionLeft()
         useStore.setState({ dragging: true })

      } else if (posX < mousePosPrev.x - diff) {
        actionRight()
        useStore.setState({ dragging: true })

      }


      setTimeout(function () {
        dragging = false
        useStore.setState({ dragging: false })
      }, 500)
    }
    down = false
  }

  const addEventListener = target => {
    // Mouse Event
    target.addEventListener("mousedown", onTouchStart)
    target.addEventListener("mousemove", onMouseMove)
    target.addEventListener("mouseup", onTouchEnd)

    // Touch surface
    target.addEventListener("touchstart", onTouchStart)
    target.addEventListener("touchmove", onTouchMove)
    target.addEventListener("touchend", onTouchEnd)
  }

  const resetEventListener = target => {
    if(target === null) return
    target.removeEventListener("mousedown", onTouchStart)
    target.removeEventListener("mousemove", onMouseMove)
    target.removeEventListener("mouseup", onTouchEnd)
    target.removeEventListener("touchstart", onTouchStart)
    target.removeEventListener("touchmove", onTouchMove)
    target.removeEventListener("touchend", onTouchEnd)
  }

  return { resetEventListener, addEventListener }
}
