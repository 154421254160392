import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import vertexShader from "../shaders/galerie/vertex_vague.glsl"
import fragmentShader from "../shaders/galerie/fragment.glsl"
import vertexShader_Points from "../shaders/pointsParticules/vertex_03_explosion.glsl"
import fragmentShader_Points from "../shaders/pointsParticules/fragment.glsl"
import { Reflector } from "../shaders/reflexion/Reflector"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import {
  generatePointsFromTexture,
  generateTargetPosition,
  generatePositionsFromFormula,
  generatePointsFromFormula,
} from "../../@utils/CloudPoints"
import ParametricEquations from "../../@utils/ParametricEquations"
import Carrousel from "../galerie/Carrousel"
import { MaterialLoader } from "three"
import { useStore } from "../galerie/store"
import gsap from "gsap"
import { CreateSun } from "../sun/CreateSun"
import { MouseMoveCamera } from "../../@utils/MouseMoveCamera"
import ExplodeVisuel from "../galerie/ExplodeVisuel"
import galerieTempTexture from "../../../static/galerie/galerie_tmp.jpg"
import { isMobile } from "../../@utils/isMobile"

class SceneGalerie {
  constructor() {
    this.ItemInFrontOfCamera_NUM = 6
    this.orbit = false
    this.loader = new GLTFLoader()
    this.textureLoader = new THREE.TextureLoader()
    this.enabled = false
    this.assetsLoaded = false
    this.groundWidth = 2000

    if (typeof window !== `undefined`) {
      this.domElement = document.createElement("CANVAS")
      this.sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    }
    // Sky Texture URl
    // this.SkytexturesUrl = "/galerie/env/cape_hill.jpg"
    this.SkytexturesUrl = "/galerie/env/sky_1.jpg"
    // Carrousel
    this.carrouselItemsDatas = []
    // Thumbnails Textures Loaded Array
    this.textures = []
    // current obj on Hover
    this.currentHoverObj = null
    // Grounds textures
    this.sandColorTextureUrl = "/sand/color.jpg"
    this.sandOCCTextureUrl = "/sand/occ.jpg"
    this.sandNormalTextureUrl = "/sand/normal.jpg"
    this.sandRoughTextureUrl = "/sand/rough.jpg"
    this.clock = new THREE.Clock()
    this.mouse = new THREE.Vector2(0, 0)
    // Put every EventListener in a local variable to remove it on unmount later
    this.MouseMoveHandler_Bound = this.mouseMoveHandler.bind(this)
    this.MouseDownHandler_Bound = this.mouseDownHandler.bind(this)
    this.MouseUpHandler_Bound = this.mouseUpHandler.bind(this)
    this.ResizeHandler_Bound = this.resizeHandler.bind(this)
    // CACHED MEMORY, Allow me to clean up the scene at unmount
    // this.meshesCached = []
    this.texturesSand = []
    this.raycaster = new THREE.Raycaster()
    this.INTERSECTED = {}
  }

  // Init Call From Outside
  setup(datas) {
    this.carrouselItemsDatas = datas

    // EventListener
    if (typeof window !== `undefined`) {
      document.addEventListener("mousemove", this.MouseMoveHandler_Bound)
      this.domElement.addEventListener(
        "touchstart",
        this.MouseDownHandler_Bound
      )
      this.domElement.addEventListener("mousedown", this.MouseDownHandler_Bound)
      this.domElement.addEventListener("touchend", this.MouseUpHandler_Bound)
      this.domElement.addEventListener("touchstart", this.MouseDownHandler_Bound)
      this.domElement.addEventListener("mouseup", this.MouseUpHandler_Bound)
      window.addEventListener("resize", this.ResizeHandler_Bound)
    }

    this.scene = new THREE.Scene()

    // Base camera
    this.camera = new THREE.PerspectiveCamera(
      80,
      this.sizes.width / this.sizes.height,
      10,
      4000
    )
    // this.camera.lo
    this.setCameraPosition()
    this.scene.add(this.camera)

    // Controls
    if (this.orbit) {
      this.controls = new OrbitControls(this.camera, this.domElement)
      this.controls.enableDamping = true
      this.controls.dampingFactor = 0.75
    }
    /**
     * Renderer
     */
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.domElement,
      // alpha: true,
      antialias: true,
    })
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)
    this.renderer.toneMappingExposure = 1
    /**
     * Debug
     */
    // this.gui = new dat.GUI()
    this.init().catch(function (err) {
      console.error(err)
    })
  }
  setCameraPosition() {
    this.camera.position.x = 80
    this.camera.position.y = 60
    this.camera.position.z = 250
    this.cameraTarget = new THREE.Vector3(5, 20, -100)
    this.camera.lookAt(this.cameraTarget)
  }
  async init() {
    await this.loadAssets()
    this.assetsLoaded = true
    useStore.setState({ assetsLoaded: true })
    useStore.setState({ camera: this.camera })
    useStore.setState({ cameraTarget: this.cameraTarget })

    this.addLights()
    this.addObjects()
    this.setupEnvironment()
    this.MouseMoveActions = MouseMoveCamera(this.camera)

    this.render()
  }

  async loadAssets() {
    const promiseArray = []
    // Textures
    for (let i = 0; i < this.carrouselItemsDatas.length; i++) {
      let textureUrl = galerieTempTexture
      if (
        this.carrouselItemsDatas[i].featuredImage.node === null ||
        this.carrouselItemsDatas[i].featuredImage.node.localFile === null
      )
        textureUrl = galerieTempTexture
      else
        textureUrl = this.carrouselItemsDatas[i].featuredImage.node.localFile
          .childImageSharp.gatsbyImageData.images.fallback.src

      promiseArray.push(this.textureLoader.loadAsync(textureUrl))
    }
    // Sky
    promiseArray.push(this.textureLoader.loadAsync(this.SkytexturesUrl))
    this.textures = await Promise.all(promiseArray)

    // Sand Textures
    const [color, normal, occ, rough] = await Promise.all([
      this.textureLoader.loadAsync(this.sandColorTextureUrl),
      this.textureLoader.loadAsync(this.sandNormalTextureUrl),
      this.textureLoader.loadAsync(this.sandOCCTextureUrl),
      this.textureLoader.loadAsync(this.sandRoughTextureUrl),
    ])
    this.sandColorTexture = color
    this.sandNormalTexture = normal
    this.sandOccTexture = occ
    this.sandRoughTexture = rough
  }

  addLights() {
    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.65)
    this.scene.add(ambientLight)

    const light = new THREE.PointLight(0xffffff, 0.5)
    light.position.x = 120
    light.position.y = 50
    light.position.z = -300
    this.scene.add(light)
  }

  //  Add Objetcs to the Scene
  // **************************************************************
  addObjects() {
    // Init Carrousel
    // ************************************************
    const carrouselTextures = this.textures.slice(0, this.textures.length - 1)
    this.carrousel = new Carrousel(
      carrouselTextures,
      this.carrouselItemsDatas,
      250
    )
    useStore.setState({ carrousel: this.carrousel })
    // firstItemID : put the first visuel in front of the camera
    this.carrousel.init(this.ItemInFrontOfCamera_NUM)
    // this.carrousel.init(11)

    // Add it to the scene
    //**************************************************
    this.group = new THREE.Group()
    this.carrousel.group.position.y = 10
    this.carrousel.group.position.x = -65
    this.carrousel.group.position.z = -460
    this.carrousel.group.rotation.set(0, 0.85, 0)
    // add Items
    this.group.add(this.carrousel.group)
    this.scene.add(this.group)
  }

  //  Environment - Backgrounds
  // **************************************************************
  setupEnvironment() {
    /**
     * Décors
     **************************************/
    // Sky
    const geometry = new THREE.BoxGeometry(
      this.groundWidth * 3,
      this.groundWidth * 3,
      this.groundWidth * 3,
      12
    )
    // invert the geometry on the x-axis so that all of the faces point inward
    const texture = this.textures[this.textures.length - 1]
    geometry.scale(-1, 1, 1)
    // texture.repeat.x = 0.95
    const skyMaterial = new THREE.MeshBasicMaterial({
      map: texture,
    })
    const skyMesh = new THREE.Mesh(geometry, skyMaterial)
    // skyMesh.scale.set(0.75, 1, 1)
    skyMesh.position.y = -105
    skyMesh.position.z = 400
    skyMesh.rotateY(-0.15)
    this.scene.add(skyMesh)

    // Sun
    /*
    1. créer un plan
    2. shader fragment qui réplique un soleil
    */
    // this.sun = CreateSun()
    // this.sun.position.set(400, 300, -950)
    // this.scene.add(this.sun)

    // Ground
    // ******************************************
    const groundPosY = -10
    const waterGeometry = new THREE.CircleGeometry(this.groundWidth, 4)
    // Reflexion
    this.ground = new Reflector(waterGeometry, {
      clipBias: 0.07,
      textureWidth: window.innerWidth * window.devicePixelRatio,
      textureHeight: window.innerHeight * window.devicePixelRatio,
      color: 0xdcdcdc,
      depthBuffer: true,
      // fog: true,
      // fogColor: this.scene.fog.color,
      // fogNear: this.scene.fog.near,
      // fogFar: this.scene.fog.far,
    })

    this.ground.material.transparent = true
    this.ground.material.uniforms.opacity.value = 0.15
    this.ground.rotateX(-Math.PI / 2)
    this.ground.position.y = groundPosY

    this.scene.add(this.ground)
    // this.meshesCached.push(this.ground)

    // solid Ground
    // *********************************
    const groundGeometry = new THREE.PlaneGeometry(
      this.groundWidth,
      this.groundWidth
    )

    this.sandColorTexture.repeat.x = 25
    this.sandColorTexture.repeat.y = 25
    this.sandColorTexture.wrapS = THREE.MirroredRepeatWrapping
    this.sandColorTexture.wrapT = THREE.MirroredRepeatWrapping
    this.sandColorTexture.generateMipmaps = false
    this.sandColorTexture.minFilter = THREE.NearestFilter
    // Ambient Occlusion
    this.sandOccTexture.repeat.x = 20
    this.sandOccTexture.repeat.y = 20
    this.sandOccTexture.wrapS = THREE.RepeatWrapping
    this.sandOccTexture.wrapT = THREE.RepeatWrapping
    this.sandOccTexture.generateMipmaps = false
    this.sandOccTexture.minFilter = THREE.NearestFilter
    // Rough map
    this.sandRoughTexture.repeat.x = 20
    this.sandRoughTexture.repeat.y = 20
    this.sandRoughTexture.wrapS = THREE.RepeatWrapping
    this.sandRoughTexture.wrapT = THREE.RepeatWrapping
    this.sandRoughTexture.generateMipmaps = false
    this.sandRoughTexture.minFilter = THREE.NearestFilter
    // Normal Map

    this.sandNormalTexture.wrapS = THREE.RepeatWrapping
    this.sandNormalTexture.wrapT = THREE.RepeatWrapping
    this.sandNormalTexture.generateMipmaps = false
    this.sandNormalTexture.minFilter = THREE.NearestFilter

    const material = new THREE.MeshStandardMaterial({
      fog: true,
    })
    material.map = this.sandColorTexture
    material.aoMap = this.sandOccTexture
    material.aoMapIntensity = 1.5
    material.roughnessMap = this.sandRoughTexture
    material.normalMap = this.sandNormalTexture
    material.normalScale.set(0.1, 0.1)

    this.solidGround = new THREE.Mesh(groundGeometry, material)
    this.solidGround.geometry.setAttribute(
      "uv2",
      new THREE.BufferAttribute(waterGeometry.attributes.uv.array, 2)
    )

    this.solidGround.rotateX(-Math.PI / 2)
    this.solidGround.position.y = groundPosY - 2
    this.scene.add(this.solidGround)
    // this.meshesCached.push(this.solidGround)
  }

  //  EVENTLISTENERS
  // **************************************************************
  mouseMoveHandler(event) {
    event.preventDefault()
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1

    useStore.setState({ mouse: { x: event.clientX, y: event.clientY } })
  }
  mouseDownHandler(event) {
    this.mouseDown = true
  }
  mouseUpHandler(event) {
    this.mouseDown = false
    this.mouseUp = true
  }
  resizeHandler() {
    // Update sizes
    this.sizes.width = window.innerWidth
    this.sizes.height = window.innerHeight

    // Update camera
    this.camera.aspect = this.sizes.width / this.sizes.height
    this.camera.updateProjectionMatrix()

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }
  // removeEventListeners() {
  //   document.removeEventListener("mousemove", this.MouseMoveHandler_Bound)
  //   window.removeEventListener("resize", this.ResizeHandler_Bound)
  // }

  // update - raf
  // **************************************************************
  render() {
    if (this.enabled && this.assetsLoaded) {
      const elapsedTime = this.clock.getElapsedTime()
      this.carrousel.update(elapsedTime)
      this.ground.material.uniforms.u_time.value = elapsedTime
      if (this.orbit) this.controls.update()
      // Camera Rotation on Mouse Move
      const updateCameraTargetPos = this.MouseMoveActions.updateCameraTarget(
        this.cameraTarget
      )
      this.cameraTarget.x = updateCameraTargetPos.x
      this.cameraTarget.y = updateCameraTargetPos.y
      // Camera target
      this.camera.lookAt(this.cameraTarget)
      // si le paneau de sélection des entreprises ou tri par sectur est afficher ne pas update le raycaster
      if (!useStore.getState().sortPanelOpen) this.updateRaycaster()
      // Render
      this.renderer.render(this.scene, this.camera)
      // Call tick again on the next frame
      this.rafID = window.requestAnimationFrame(this.render.bind(this))
    }
  }

  // RAyCaster -- Hover on Thumb galerie
  // *******************************************************************
  updateRaycaster() {
    // HOVER CARROUSEL find intersections
    this.raycaster.setFromCamera(this.mouse, this.camera)
    const intersects = this.raycaster.intersectObjects(
      this.carrousel.groupItems.children
    )

    if (intersects.length > 0) {
      // Change intersection target from last Frame
      if (this.INTERSECTED !== intersects[0].object) {
        this.currentHoverObj = null
        // Mouse Leave Precedent One
        //*******************************/
        for (let i = 0; i < this.carrousel.items.length; i++) {
          const obj = this.carrousel.items[i]
          const mesh = obj.mesh
          if (this.INTERSECTED === mesh) {
            // tester si c'est bien la marque en cours
            if (obj.order === this.ItemInFrontOfCamera_NUM) obj.OnMouseLeave()
          }
        }

        // NEW ONE
        this.INTERSECTED = intersects[0].object
        // Mouse Enter
        // ****************************
        for (let i = 0; i < this.carrousel.items.length; i++) {
          const obj = this.carrousel.items[i]
          const mesh = obj.mesh
          if (this.INTERSECTED === mesh) {
            // tester si c'est bien la marque en cours
            if (obj.order === this.ItemInFrontOfCamera_NUM) {
              this.currentHoverObj = obj
              obj.OnMouseEnter()

              // if is Mobile > Go explosion
              // if (isMobile()) obj.explosion()
            }
          }
        }
        this.mouseUpOnItem = false
        this.mouseDownOnItem = false
      }
      // On Hover le meme ITEM
      else {
        if (this.mouseDown) {
          this.mouseDownOnItem = true
        }
        if (this.mouseDownOnItem && this.mouseUp) this.mouseUpOnItem = true
      }

      // Mouse UP on Current Slide > explosion
      // *********************************
      if (
        this.currentHoverObj !== null &&
        !this.currentHoverObj.moving &&
        !this.currentHoverObj.exploding &&
        this.mouseUpOnItem &&
        this.INTERSECTED === this.currentHoverObj.mesh

        // OR


      ) {
        this.currentHoverObj.explosion()
      }
    }
    // No Intersection
    else {
      // If a mesh was in intersection before > Mouse Leave
      if (this.INTERSECTED) {
        // Mouse Leave
        for (let i = 0; i < this.carrousel.items.length; i++) {
          const obj = this.carrousel.items[i]
          const mesh = obj.mesh
          if (this.INTERSECTED === mesh) {
            if (obj.order === this.ItemInFrontOfCamera_NUM) {
              obj.OnMouseLeave()
              this.onCurrentItem = false
            }
          }
        }
      }
      this.INTERSECTED = null
    }

    this.mouseUp = false
  }

  enable() {
    this.enabled = true
    // position
    if (this.camera) {
      this.setCameraPosition()
    }
    // reset Explode Item
    if (this.carrousel) {
      ExplodeVisuel.reset()
      // show carrouselItem qui à déjà été sélectionné
      for (let i = 0; i < this.carrousel.items.length; i++) {
        const obj = this.carrousel.items[i]
        obj.reset()
      }
    }
    this.render()
  }
  // UnMount
  //****************************************************************** */
  disable() {
    // Kill the Request Animation Frame
    //TODO: pause the RAF
    this.enabled = false
    window.cancelAnimationFrame(this.rafID)
    this.rafID = undefined
  }
}

// ACTIONS
// *********************************************************

const gl = new SceneGalerie()

export default gl
