import { lerp } from "./math.utils"

// Mouse Camera Movement
export const MouseMoveCamera = camera => {
  const mouseDiff = { x: 0, y: 0 }

  const onMouseMove = event => {
    var movementX = event.clientX
    var movementY = event.clientY
    const ratio = 0.01
    let diffX = (movementX - window.innerWidth / 2) * ratio
    let diffY = (movementY - window.innerWidth / 2) * ratio
    mouseDiff.x = diffX
    mouseDiff.y = diffY
  }

  window.addEventListener("mousemove", onMouseMove)
  window.addEventListener("pointermove", onMouseMove)

  const cameraRotation = camera => {
    // Lerp Camera Rotation
    const rot_x = camera.rotation.x
    const rot_y = camera.rotation.y
    let newRot_y = lerp(rot_y, -mouseDiff.x, 0.05)
    let newRot_x = lerp(rot_x, -Math.PI / 2 - mouseDiff.y, 0.05)
    const cameraRotation = { x: newRot_x, y: newRot_y }
    return cameraRotation
  }

  const updateCameraTarget = cameraTarget => {
    // Lerp Camera Rotation
    const pos_x = cameraTarget.x
    const pos_y = cameraTarget.y
    let newPos_y = lerp(pos_y, -mouseDiff.y * 0.5, 0.05)
    let newPos_x = lerp(pos_x, mouseDiff.x, 0.05)
    const cameraTargetPos = { x: newPos_x, y: newPos_y }

    return cameraTargetPos
  }

  return { cameraRotation, mouseDiff, updateCameraTarget }
}
