import * as THREE from "three"
import CarrouselItem from "./CarrouselItem"
import gsap from "gsap"
import ExplodeVisuel from "./ExplodeVisuel"

// SI plus de  Visuels > les placer hors champ sur la gauche, et attendre leur tour pour repasser à droite

export default class Carrousel {
  constructor(textures, datas, radius) {
    // this.nbItems = textures.length
    this.nbItems = 20
    this.totalItems = textures.length
    this.datas = datas
    this.radius = radius
    const itemWidth = 70
    this.geometryItem = new THREE.PlaneGeometry(
      itemWidth,
      itemWidth / 2,
      16,
      16
    )
    this.group = new THREE.Group()
    this.groupItems = new THREE.Group()
    // CarrouselItem Objects
    this.items = []
    // split in
    this.textures = textures
    // On génére un tableau d'IDs suivant l'ordre des textures
    // this.IDs = this.textures.map((value, index) => index)
    this.isPlaying = false
    // TODO: limiter le carrousel à 20 items, et les suivant ont les positionne au mem
    this.SETTINGS = {
      startX: -100,
      radius: null,
      // width: this.nbItems * (itemWidth + itemWidth / 2),
      width: this.nbItems * itemWidth * 1.5,
      ratio: 0.7,
      depth: 495,
      amplitude: -200,
      points: null,
    }
    this.SETTINGS.radius = this.SETTINGS.width * this.SETTINGS.ratio * 0.5
    // Coubre bezier
    this.curve = new THREE.CubicBezierCurve3(
      new THREE.Vector3(-this.SETTINGS.radius, 0, 0),
      new THREE.Vector3(this.SETTINGS.amplitude, 0, this.SETTINGS.depth),
      new THREE.Vector3(-this.SETTINGS.amplitude, 0, this.SETTINGS.depth),
      new THREE.Vector3(this.SETTINGS.radius, 0, 0)
    )
    this.SETTINGS.points =  this.curve.getPoints(this.nbItems - 1)
    // const geometry = new THREE.BufferGeometry().setFromPoints(points)
    // const material = new THREE.LineBasicMaterial({
    //   color: 0xff0000,
    //   linewidth: 1,
    // })
    // const curveObject = new THREE.Line(geometry, material)


  }
  init(firstItemId) {
    for (let i = 0; i < this.totalItems; i++) {
      let pos = null
      let rotation = null
      if (i < this.nbItems) {
        pos = new THREE.Vector2(this.SETTINGS.points[i].x, this.SETTINGS.points[i].z)
        rotation = this.getRotationItemFromCurve(this.nbItems, i,  this.curve)
      }
      else {
        pos = new THREE.Vector2(this.SETTINGS.points[0].x, 0)
        rotation = this.getRotationItemFromCurve(this.nbItems, 0,  this.curve)
      }

      let slug = {
        fr: this.datas[i].slug,
        en: this.datas[i].slug
      }
      if (this.datas[i].translations.length > 0)
        slug.en = this.datas[i].translations[0].slug

      this.drawItem(pos, rotation, this.textures[i], i, slug)
    }

    // Position Explode Item
    this.explodeMesh = ExplodeVisuel.createMesh(this.textures[0])
    // ExplodeVisuel.reset()
    this.explodeMesh.position.setY(-100)
    this.explodeMesh.position.setX(this.SETTINGS.points[firstItemId].x)
    this.explodeMesh.position.setZ(this.SETTINGS.points[firstItemId].z)
    this.explodeMesh.rotateY(
      this.getRotationItemFromCurve(this.nbItems, firstItemId,  this.curve) +
        Math.PI
    )
    this.group.add(this.explodeMesh)
    this.group.add(this.groupItems)
  }

  drawItem(pos, rotation = 0, texture, id, slug) {
    const item = new CarrouselItem(texture, this.geometryItem, id, pos, slug)
    this.items.push(item)
    const mesh = item.mesh
    mesh.rotateY(rotation)
    this.groupItems.add(mesh)
  }

  getRotationItemFromCurve(nbItems, indexItem, curve) {
    const ratio = 1 / (nbItems - 1)
    const tangentVector = curve.getTangentAt(ratio * indexItem)
    let angle = tangentVector.angleTo(new THREE.Vector3(1, 0, 0))
    if (tangentVector.z > 0) angle *= -1
    return angle
  }
  getRotationItem(indexItem) {
    const angle = (Math.PI * 2) / this.nbItems
    let rotation = -(angle * indexItem + Math.PI / 2) % (2 * Math.PI)
    return rotation
  }
  // Move Right
  move(direction) {
    if (this.isPlaying) return

    // Loop over items and get new rotation and position
    for (let i = 0; i < this.totalItems; i++) {
      // 1. Change items order
      if (direction === "right") {
        if (this.items[i].order > 0) this.items[i].order--
        else this.items[i].order = this.totalItems - 1
      } else {
        if (this.items[i].order < this.totalItems - 1) this.items[i].order++
          else this.items[i].order = 0
      }
      // 2. Positionner les items suivant leur nouvel ordre sur la courbe
      // getPosition + get Rotation
      // on bouge si le nouvel ordre fait partie de la courbe carrousel
      if (this.items[i].order < this.nbItems ) {
        this.items[i].moving = true
        const rotation = this.getRotationItemFromCurve(this.nbItems, this.items[i].order, this.curve)
        // si extremite on n'utilise pas de gsap on change directement la position
        if( this.items[i].order === 0 || this.items[i].order === this.nbItems -1 ) {
          this.items[i].mesh.position.set(this.SETTINGS.points[this.items[i].order].x, 0, this.SETTINGS.points[this.items[i].order].z)
          // rotation
          this.items[i].mesh.rotation.set(0,rotation,0)
        } else {
          // position gsap
          gsap.to(this.items[i].mesh.position, {
            x: this.SETTINGS.points[this.items[i].order].x,
            z: this.SETTINGS.points[this.items[i].order].z,
            onComplete: () => {
              this.isPlaying = false
              this.items[i].moving = false
            },
          })
          // rotation
          gsap.to(this.items[i].mesh.rotation, {
            y: rotation
          })
        }
      }
    }

    this.isPlaying = true
  }

  update(elapsedTime) {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i]
      item.update(elapsedTime)
    }
    // Explode visuel
    ExplodeVisuel.update(elapsedTime)
  }

  reset() {}
}
