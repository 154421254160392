import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
// import gl as singleton + setup is called in parallel
import gl from "../@core/scenes/galerie" // singleton
import { useStore } from "../@core/galerie/store"
import { useStore as useMainStore } from "../@core/scenes/mainStore"
import { useTransition, animated, useSpring } from "react-spring"
import { ClickAndMove } from "../@utils/ClickAndMove"
import { UIContainer } from "../components/ui/UIContainer"
import { DragAndDropUI } from "../components/ui/dragAndDrop"
import { graphql } from "gatsby"
import { SortSelectNavigation } from "../components/ui/galerie/SortSelectNavigation"
import { Helmet } from "react-helmet"
import LANGUAGES from "../Const/languages"

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export default function GalerieProjets({ data }) {
  let posts = data.allWpEntreprise.nodes

  // Ordre aléatoire des marques
  if (useStore.getState().datasSlides === null) {
    posts = shuffleArray(posts)
  }

  // FILTER LANGUAGE ONLY FR, we'll use translations> slug to find the english slug
  posts = posts.filter(value => value.language.code === "FR")

  // Logo colorc
  useEffect(() => {
    useMainStore.setState({ theme: "dark" })
  }, [])

  // DATAS END
  const canvasRef = useRef()
  const assetsLoaded = useStore(state => state.assetsLoaded)
  useStore.setState({ nbSlides: posts.length })
  useStore.setState({ datasSlides: posts })
  const actions = useStore(state => state.actions)

  // Start Camera introduction when Camera is set and scene ready
  useEffect(() => {
    if (assetsLoaded) {
      // Intro Camera movement
      actions.startCameraIntro()
    }
  }, [assetsLoaded, actions])

  // Manage Main GL Singleton
  // ********************************************
  useEffect(() => {
    canvasRef.current.appendChild(gl.domElement)
    // Only one time
    if (!assetsLoaded) {
      gl.setup(posts)
    }
    // enable RAF
    gl.enable()

    return () => {
      // unMount & Clean Canvas container & Disable RAF
      if (canvasRef.current)
        while (canvasRef.current.firstChild) {
          canvasRef.current.removeChild(canvasRef.current.firstChild)
        }
      //stop RAF
      gl.disable()
    }
  }, [assetsLoaded, posts])

  return (
    <Layout localClass="galerie" showPointClouds={false}>
      <Helmet>
        <title>Les entreprises</title>
      </Helmet>
      <div ref={canvasRef} id="canvasGalerie"></div>
      <UIContainer>
        <MarqueDetails posts={posts} />
        <Navigation canvasTarget={canvasRef} />
      </UIContainer>
      <SortSelectNavigation />
      <OnHoverThumb />
      <DragAndDropUI />
    </Layout>
  )
}

// GraphQL query Projets
//highlight-start
export const query = graphql`
  query {
    allWpEntreprise(sort: { fields: title, order: ASC }) {
      nodes {
        language {
          code
        }
        title
        slug
        translations {
          slug
          secteurs {
            nodes {
              name
            }
          }
        }
        secteurs {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
      }
    }
  }
`
//highlight-end

const OnHoverThumb = () => {
  const LANG = useMainStore(state => state.language)

  const onHoverThumb = useStore(state => state.onHoverThumb)
  const mouse = useStore(state => state.mouse)

  const props = useSpring({
    opacity: onHoverThumb ? 1 : 0,
    left: mouse.x - 15 + "px",
    top: mouse.y - 75 + "px",
  })

  return (
    <animated.div className={`ui-circle-text on-hover-thumb`} style={props}>
      {LANGUAGES[LANG].enter}
    </animated.div>
  )
}

const MarqueDetails = props => {
  const posts = props.posts
  const LANG = useMainStore(state => state.language)
  const index = useStore(state => state.currentSlideNum)
  // const datasSlides = useStore(state => state.datasSlides)
  const transitions = useTransition(posts[index], {
    keys: null,
    from: { opacity: 0, transform: "translate3d(0,-20%,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(0,20%,0)" },
  })

  const isIntroOver = useStore(state => state.isIntroOver)
  const styles = useSpring({ opacity: isIntroOver ? 1 : 0 })

  return (
    <animated.div className="galerie-marque-detail" style={styles}>
      {transitions((styles, item) => {
        let secteurName = item.secteurs.nodes[0].name

        if (LANG === "EN")
          secteurName = item.translations[0].secteurs.nodes[0].name

        return (
          <animated.h2 style={styles} className="galerie-marque-title ">
            {item.title}
            <div className="galerie-marque-category">{secteurName}</div>
          </animated.h2>
        )
      })}
    </animated.div>
  )
}

const Navigation = prop => {
  const actions = useStore(state => state.actions)
  const assetsLoaded = useStore(state => state.assetsLoaded)
  const { resetEventListener, addEventListener } = ClickAndMove(
    actions.moveLeft,
    actions.moveRight
  )

  //Click & Move
  useEffect(() => {
    addEventListener(prop.canvasTarget.current)
    return () => {
      resetEventListener(prop.canvasTarget.current)
    }
  }, [addEventListener, prop.canvasTarget])

  const moveRightHandler = () => {
    if (assetsLoaded) {
      actions.moveRight(gl.carrousel)
    }
  }
  const moveLeftHandler = () => {
    if (assetsLoaded) actions.moveLeft(gl.carrousel)
  }

  return (
    <div className="nav-galerie-mol">
      <div onClick={moveLeftHandler} className="galerie-nav-button arrow">
        <svg
          width="21"
          height="18"
          viewBox="0 0 21 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="20"
            height="2.47487"
            rx="1.23744"
            transform="matrix(-1 0 0 1 21 7.77472)"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.79992 1.12554C9.31114 0.641583 8.51867 0.641583 8.02989 1.12554L0.949755 8.13586C0.460975 8.61982 0.460975 9.40448 0.949755 9.88844L8.02989 16.8988C8.51867 17.3827 9.31114 17.3827 9.79992 16.8988C10.2887 16.4148 10.2887 15.6301 9.79992 15.1462L3.6048 9.01215L9.79992 2.87812C10.2887 2.39416 10.2887 1.60951 9.79992 1.12554Z"
            fill="black"
          />
        </svg>
      </div>
      <div onClick={moveRightHandler} className="galerie-nav-button arrow">
        <svg
          width="21"
          height="18"
          viewBox="0 0 21 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7.77472"
            width="20"
            height="2.47487"
            rx="1.23744"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2001 1.12554C11.6889 0.641583 12.4813 0.641583 12.9701 1.12554L20.0502 8.13586C20.539 8.61982 20.539 9.40448 20.0502 9.88844L12.9701 16.8988C12.4813 17.3827 11.6889 17.3827 11.2001 16.8988C10.7113 16.4148 10.7113 15.6301 11.2001 15.1462L17.3952 9.01215L11.2001 2.87812C10.7113 2.39416 10.7113 1.60951 11.2001 1.12554Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  )
}
